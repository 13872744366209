import React from "react";
import PropTypes from "prop-types";
import ShowTOCButton from "./ShowTOCButton";
import TOCDesktop from "./TOCDesktop";
import ExpandableSection from "./ExpandableSection";
import { PoseGroup } from "react-pose";
import styled from "styled-components";
import posed from "react-pose";
import { media } from "../css/theme";
import back_arrow from "../images/round-arrow_back-24px_red.svg";
import MobileGuideBanner from "../components/MobileGuideBanner";

const AnimatedBackdrop = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

const Backdrop = styled(AnimatedBackdrop)`
  position: fixed;
  background-color: rgba(0, 0, 0, 30%);
  height: 100vh;
  width: 100vw;
  z-index: 2;
  top: 0;
  left: 0;
  ${media.tablet`display: none;`};
`;

const PosedDiv = posed.div({
  enter: {
    transition: { type: "tween" },
    x: 0
  },
  exit: {
    transition: { type: "tween" },
    x: "-100%"
  }
});

const SlideOutPanel = styled(PosedDiv)`
  background-color: white;
  width: calc(100% - 32px);
  max-width: 360px;
  height: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0px 8px 8px 0px;
  && h5 {
    margin-top: ;
  }
  ${props => props.theme.card3};
  ${media.tablet`display:none;`};
`;

const PanelHeader = styled.div`
  border-radius: 8px;
  padding: 16px;
  position: relative;
  ${props => props.theme.card1};
  > span {
    position: absolute;
    display: flex;
    align-items: center;
    height: calc(100% - 32px);
    > img {
      height: 32px;
      cursor: pointer;
    }
  }
  > h2,
  h6 {
    margin: 0px;
    text-align: center;
  }
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  padding: 8px;
`;

class TableOfContents extends React.PureComponent {
  state = {
    panel: false
  };

  showPanel = () => {
    this.setState({ panel: true });
  };

  hidePanel = () => {
    this.setState({ panel: false });
  };

  render() {
    const { TOC, currentPath, header, overline, buttonText } = this.props;
    return (
      <>
        <TOCDesktop TOC={TOC} currentPath={currentPath} />
        <MobileGuideBanner showPanel={this.showPanel} />
        <PoseGroup>
          {this.state.panel && [
            <Backdrop key="backdrop" />,
            <SlideOutPanel key="slideout">
              <PanelHeader>
                <span>
                  <img
                    src={back_arrow}
                    alt="close contents"
                    onClick={this.hidePanel}
                  />
                </span>
                <h6>{overline}</h6>
                <h2>{header}</h2>
              </PanelHeader>
              <ScrollableContent>
                {TOC.edges.map(({ node }) => {
                  return (
                    <ExpandableSection
                      key={node.data.Path}
                      currentPath={currentPath}
                      title={node.data.Title}
                      pages={node.data.Pages}
                      isOpen={false}
                      sectionPath={node.data.Path}
                    />
                  );
                })}
              </ScrollableContent>
            </SlideOutPanel>
          ]}
        </PoseGroup>
        <ShowTOCButton handleClick={this.showPanel}>{buttonText}</ShowTOCButton>
      </>
    );
  }
}

export default TableOfContents;

TableOfContents.propTypes = {
  TOC: PropTypes.shape({ edges: PropTypes.array.isRequired }).isRequired,
  currentPath: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  overline: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};
