import styled from "styled-components";
import React from "react";
import list_icon from "../images/round-list-24px_red.svg";
import { media } from "../css/theme";
import { ButtonFont } from "../components/common";

const Button = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  color: #f44336;
  cursor: pointer;
  position: sticky;
  top: 12px;
  z-index: 3;
  span {
    margin: 0px 8px 0px 8px;
    z-index: 1;
  }
  img {
    width: 32px;
  }
  ${media.tablet`display: none;`};
`;

export const ShowTOCButton = ({ children, handleClick }) => {
  return (
    <Button onClick={handleClick}>
      <img src={list_icon} alt="see all topics" />
      <ButtonFont>{children}</ButtonFont>
    </Button>
  );
};

export default ShowTOCButton;
