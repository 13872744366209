import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { StyledPageTOC } from "./common";
import { media } from "../css/theme";

const Container = styled.div`
  flex-shrink: 0;
  display: none;
  > div {
    position: sticky;
    top: 16px;
    > div {
      padding: 8px 0px;
    }
  }
  li,
  p {
    /* override */
    margin-bottom: 8px;
  }
  ${media.desktop`
  display: initial;
  max-width: 200px;
  `};
`;

const PageContentsDesktop = ({ pageTOC, header }) => (
  <Container>
    <div>
      <h6>{header}</h6>
      <StyledPageTOC dangerouslySetInnerHTML={{ __html: pageTOC }} />
    </div>
  </Container>
);

export default PageContentsDesktop;
PageContentsDesktop.propTypes = {
  pageTOC: PropTypes.string.isRequired
};
