import React from "react";
import { graphql, navigate } from "gatsby";
import withLayout from "../components/withLayout";
import PropTypes from "prop-types";
import {
  HR,
  WhiteContentBox,
  GuideContainer,
  PageHeader,
  PageContainer
} from "../components/common";
import { PoseGroup } from "react-pose";
import TableOfContents from "../components/TableOfContents";
import PageContentsMobile from "../components/PageContentsMobile";
import PageContentsDesktop from "../components/PageContentsDesktop";

const PageTemplate = ({
  content,
  TOC,
  header,
  toc_button,
  toc_header,
  page_contents_header
}) => {
  return (
    <>
      <PageContainer>
        <PageHeader>
          <h1>{header.data.Text}</h1>
        </PageHeader>
      </PageContainer>
      <GuideContainer>
        <TableOfContents
          header={toc_header.data.Text}
          overline={header.data.Text}
          TOC={TOC}
          currentPath={content.Path}
          buttonText={toc_button.data.Text}
        />
        <PoseGroup animateOnMount={true}>
          <WhiteContentBox key="boxkey">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => navigate(content.Section.Path)}
            >
              GUIDE / {content.Section.Title}
            </h6>
            <h1>{content.Title}</h1>
            <HR />
            <PageContentsMobile
              header={page_contents_header.data.Text}
              pageTOC={content.pageTOC}
            />
            <section
              className="markdown-content"
              dangerouslySetInnerHTML={{ __html: content.Markdown }}
            />
          </WhiteContentBox>
        </PoseGroup>
        <PageContentsDesktop
          header={page_contents_header.data.Text}
          pageTOC={content.pageTOC}
        />
      </GuideContainer>
    </>
  );
};

export default withLayout(PageTemplate);

PageTemplate.propTypes = {
  content: PropTypes.shape({
    Markdown: PropTypes.string.isRequired,
    Section: PropTypes.object.isRequired,
    Title: PropTypes.string.isRequired,
    Path: PropTypes.string.isRequired
  }).isRequired,
  TOC: PropTypes.object.isRequired,
  header: PropTypes.object.isRequired,
  toc_button: PropTypes.object.isRequired,
  toc_header: PropTypes.object.isRequired,
  page_contents_header: PropTypes.object.isRequired
};

// for pages, we extract
export const query = graphql`
  query getGuidePage($Path: String!, $Locale: String!) {
    toc_header: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "toc_header" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    toc_button: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "toc_button" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    header: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "header" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    page_contents_header: airtable(
      table: { eq: "Guide" }
      data: { ID: { eq: "page_contents_header" }, Locale: { eq: $Locale } }
    ) {
      data {
        Text
      }
    }
    TOC: allAirtable(
      filter: {
        table: { eq: "Guide Sections" }
        data: { Locale: { eq: $Locale } }
      }
      sort: { fields: data___Position, order: ASC }
    ) {
      ...TOC
    }
    content: allAirtable(filter: { data: { Path: { eq: $Path } } }) {
      edges {
        node {
          data {
            Path
            Title
            metaDescription
            siteTitle
            Markdown {
              childMarkdownRemark {
                html
                tableOfContents(pathToSlugField: "frontmatter.path")
              }
            }
            Section {
              data {
                Title
                Path
              }
            }
          }
        }
      }
    }
    layout: allAirtable(
      filter: { table: { eq: "Layout" }, data: { Locale: { eq: $Locale } } }
    ) {
      ...layout
    }
  }
`;
