import React from "react";
import posed, { PoseGroup } from "react-pose";
import navigate_next from "../images/navigate_next.svg";
import { navigate, Link } from "gatsby"; // we don't need to prefix these
import styled from "styled-components";
import PropTypes from "prop-types";

const animatedNav = posed.nav({
  preEnter: {
    y: -20,
    opacity: 0
  },
  enter: {
    y: 0,
    opacity: 1
  }
});

const DropDown = styled(animatedNav)`
  border-left: 2px solid
    ${props => (props.isBold ? props.theme.red_2 : props.theme.light_black)};
  padding-left: 8px;
  margin-left: 32px;
  && a {
    display: block;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  > img {
    transition: transform 0.5s;
    cursor: pointer;
    transform: rotate(${props => (props.isOpen ? "90deg" : "0deg")});
  }
  h4 {
    font-weight: ${props => (props.isBold ? "700" : "normal")};
    cursor: pointer;
    user-select: none;
    margin: 8px;
    color: ${props =>
      props.isBold ? props.theme.red_2 : props.theme.light_black};
    font-size: 1rem;
  }
`;

// otherwise we get an error because Link doesn't need isBold
// we use gatsby link not i18n link for this.
// color: ${props =>
//   props.isBold ? props.theme.red_2 : props.theme_light_black};
const PageTitle = styled(({ isBold, ...rest }) => <Link {...rest} />)`
  font-weight: ${props => (props.isBold ? "700" : "normal")};
  color: ${props =>
    props.isBold ? props.theme.red_2 : props.theme.light_black} !important;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0px;
  padding: 8px 0px;
`;

class ExpandableSection extends React.PureComponent {
  constructor(props) {
    super(props);
    // on page load, we set expanded / bold if needed.
    const isActive = props.currentPath.includes(props.sectionPath);
    this.state = {
      isBold: isActive,
      isOpen: isActive || props.isOpen
    };
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleNavigate = path => {
    navigate(path);
  };

  render() {
    const { currentPath, title, pages, sectionPath } = this.props;
    return (
      <React.Fragment>
        <SectionTitle isOpen={this.state.isOpen} isBold={this.state.isBold}>
          <img
            src={navigate_next}
            onClick={this.toggleOpen}
            alt="expand section"
          />
          <Link to={sectionPath}>
            <h4>{title}</h4>
          </Link>
        </SectionTitle>
        <PoseGroup preEnterPose="preEnter">
          {this.state.isOpen && (
            <DropDown key="dropdownkey" isBold={this.state.isBold}>
              {pages.map(page => (
                <PageTitle
                  key={page.data.Path}
                  to={page.data.Path}
                  isBold={page.data.Path === currentPath}
                >
                  {page.data.Title}
                </PageTitle>
              ))}
            </DropDown>
          )}
        </PoseGroup>
      </React.Fragment>
    );
  }
}

export default ExpandableSection;

ExpandableSection.propTypes = {
  currentPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  sectionPath: PropTypes.string.isRequired
};
