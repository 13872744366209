import React from "react";
import styled from "styled-components";
import { media } from "../css/theme";
import { ButtonFont, StyledPageTOC } from "../components/common";
import navigate_next from "../images/navigate_next.svg";
import PropTypes from "prop-types";
import posed, { PoseGroup } from "react-pose";

const animatedNav = posed.nav({
  preEnter: {
    y: -20,
    opacity: 0
  },
  enter: {
    y: 0,
    opacity: 1
  }
});

const DropDown = styled(animatedNav)`
  margin: 8px 0px 0px 16px;
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  ${media.desktop`display: none;`};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  > img {
    transition: transform 0.5s;
    transform: rotate(${props => (props.isOpen ? "90deg" : "0deg")});
  }
`;

class PageContentsMobile extends React.Component {
  state = {
    isOpen: false
  };

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { header, pageTOC } = this.props;
    return (
      <Container>
        <ButtonContainer isOpen={this.state.isOpen}>
          <img
            onClick={this.toggleOpen}
            src={navigate_next}
            alt="expand section"
          />
          <ButtonFont onClick={this.toggleOpen}>{header}</ButtonFont>
        </ButtonContainer>
        <PoseGroup preEnterPose="preEnter">
          {this.state.isOpen && (
            <DropDown key="DropDownKey" onClick={this.toggleOpen}>
              <StyledPageTOC dangerouslySetInnerHTML={{ __html: pageTOC }} />
            </DropDown>
          )}
        </PoseGroup>
      </Container>
    );
  }
}

export default PageContentsMobile;

PageContentsMobile.propTypes = {
  header: PropTypes.string.isRequired,
  pageTOC: PropTypes.string.isRequired
};
