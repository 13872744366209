import React from "react";
import styled from "styled-components";
import logo_icon from "../images/logo_icon_60x60.svg";
import posed, { PoseGroup } from "react-pose";
import { media } from "../css/theme";
import Link from "../i18n/Link.js";

const PosedDiv = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: { type: "tween" }
  },
  exit: {
    y: -30,
    opacity: 0,
    transition: { type: "tween" }
  }
});

const Container = styled(PosedDiv)`
  display: flex;
  justify-content: space-around;
  height: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 0px 16px 0px 16px;
  ${props => props.theme.card1};
  ${media.tablet`display: none;`};
`;

class MobileGuideBanner extends React.Component {
  state = {
    visible: false
  };

  handleScroll = () => {
    if (window.scrollY > 145 && !this.state.visible) {
      this.setState({ visible: true });
    } else if (window.scrollY < 145 && this.state.visible) {
      this.setState({ visible: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <PoseGroup>
        {this.state.visible && (
          <Container key="bannerkey">
            <Link to="/">
              <img src={logo_icon} alt="go home" />
            </Link>
          </Container>
        )}
      </PoseGroup>
    );
  }
}

export default MobileGuideBanner;
