import React from "react";
import ExpandableSection from "./ExpandableSection";
import styled from "styled-components";
import PropTypes from "prop-types";
import { media } from "../css/theme";

const DesktopContainer = styled.div`
  display: none;
  margin: 16px 8px 0px 0px;
  flex-shrink: 0;
  ${media.tablet`
  display: initial;
  > div {
    width: 250px;
    position: sticky;
    top: 16px;
  }
  `};
`;

class TOCDesktop extends React.Component {
  render() {
    const { TOC, currentPath } = this.props;
    return (
      <DesktopContainer>
        {/* div is a hacky fix to give sticky powers*/}
        <div>
          {TOC.edges.map(({ node }) => {
            return (
              <ExpandableSection
                key={node.data.Path}
                currentPath={currentPath}
                title={node.data.Title}
                pages={node.data.Pages}
                isOpen={false}
                sectionPath={node.data.Path}
              />
            );
          })}
        </div>
      </DesktopContainer>
    );
  }
}
export default TOCDesktop;

TOCDesktop.propTypes = {
  TOC: PropTypes.shape({ edges: PropTypes.array.isRequired }).isRequired,
  currentPath: PropTypes.string.isRequired
};
